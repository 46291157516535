@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url('./static/Avenir.otf') format('truetype');
}

@font-face {
  font-family: 'Avenir Light';
  src: local('Avenir Light'),
    url('./static/Avenir-Light.otf') format('truetype');
}

@font-face {
  font-family: 'Roslindale Bold';
  src: local('Roslindale Bold'),
    url('./static/Roslindale-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Roslindale Italic';
  src: local('Roslindale Italic'),
    url('./static/Roslindale-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Roslindale';
  src: local('Roslindale'),
    url('./static/Roslindale-Regular.woff') format('woff');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
